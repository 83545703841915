<template>
  <div id="calendar-header-wrapper">
    <div class="calendar-header-title">
      <h2 class="title">{{ title }}</h2>
      <v-schedule-legend :isSurgicalMap="isSurgicalMapCurrentView" />
      <div v-visible="showTodayButton()">
        <b-button
          id="calendar-button"
          class="ml-2"
          variant="outline-primary"
          outline
          @click="todayButton"
        >
          <v-calendar class="calendar-icon" />
        </b-button>
        <b-tooltip
          target="calendar-button"
          triggers="hover"
          placement="bottom"
          class="tooltip-calendar"
        >
          <span class="d-block text-left">
            Clique aqui para <br />
            voltar para a data <br />
            atual da agenda
          </span>
        </b-tooltip>
      </div>
    </div>
    <div class="calendar-datepicker">
      <v-chevron-left class="chevron" @click="prevOnCalendar" />
      <date-picker
        class="date"
        :class="{ month: currentView === 'dayGridMonth' }"
        :type="getPickerType()"
        v-model="calendarTitle"
        :placeholder="String(calendarTitle)"
        format="MMMM [de] YYYY"
        :clearable="false"
        :lang="langDatePicker"
        @input="customOnCalendar"
        ref="datePicker"
      ></date-picker>
      <v-chevron-right class="chevron" @click="nextOnCalendar" />
    </div>
    <div class="calendar-header-actions">
      <Loading
        :class="{ reload: true, loading: loadingAppointments }"
        @click="() => !loadingAppointments && loadAppointments()"
      />
      <div
        v-if="!isSurgicalMapCurrentView"
        class="search-button"
        @click="$bvModal.show('search-appointment-modal')"
      >
        <v-search />
      </div>
      <MultiSelectProfessionals
        :max="4"
        placeholder="Médico/Sala"
        :disabled="(userHasRole('DOCTOR') && !userHasPermission('FpAgenSal10'))"
      />
      <div v-if="!isSurgicalMapCurrentView">
        <Icon tooltip="Defina o(s) filtro(s) para  visualização da agenda!">
          <b-button class="filter" id="filter" @click="openFilter">
            <b-popover
              target="filter"
              triggers="click focus blur"
              placement="top"
            >
              <b-row>
                <b-col>
                  <!-- <v-autocomplete
                  placeholder="Sala/Setor"
                  v-model="tempRoomId"
                  :options="clinicRooms.map(room => ({label: room.name, value: room.id }))"
                  class="mb-2"
                /> -->
                  <v-autocomplete
                    placeholder="Tipo de procedimento"
                    v-model="tempProcedureType"
                    :options="procedureOptions"
                    class="mb-2"
                  />
                  <v-autocomplete
                    ref="asd"
                    placeholder="Financeiro"
                    v-model="tempFinanceiroValue"
                    :options="fatura"
                    class="mb-2"
                  />
                  <v-autocomplete
                    placeholder="Tipo de pendência"
                    v-model="pendency"
                    :options="pendenciesOptions"
                    class="mb-2"
                  />
                  <b-form-group>
                    <multi-select-priority
                      placeholder="Prioridade"
                      v-model="priorityLevelValue"
                    />
                  </b-form-group>

                  <b-row>
                    <b-col>
                      <b-button variant="outline-primary" @click="cleanFilter">
                        Redefinir
                      </b-button>
                    </b-col>
                    <b-col>
                      <b-button variant="primary" @click="confirmFilter()">
                        Confirmar
                      </b-button>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-popover>
            <v-filter />
          </b-button>
        </Icon>
      </div>
      <v-select
        class="calendar-options mr-1"
        v-model="currentViewOption"
        :options="calendarViewOptions"
        border="2px solid var(--blue-500)"
      />
      <slot name="print" />
      <section
        v-show="currentView !== 'dayGridMonth' && !isSurgicalMapCurrentView"
        class="interval-container"
      >
        <b-button
          variant="primary"
          @click="showIntervalOptions = !showIntervalOptions"
          v-b-tooltip.hover.top="'Defina o período de visualização da agenda'"
        >
          <v-time class="timer-icon" />
        </b-button>
        <div
          v-if="showIntervalOptions"
          class="interval-options"
          v-click-outside="() => (showIntervalOptions = false)"
        >
          <p
            v-for="interval in intervalOptions"
            :key="interval.value"
            @click="setInterval(interval.value)"
            :class="{ active: interval.value === calendarInterval }"
          >
            {{ interval.label }}
          </p>
        </div>
      </section>

      <b-button
        variant="primary btn-new-appointment"
        v-can="'FpAgen2'"
        @click="openModal"
      >
        <v-plus class="icon-plus" />
      </b-button>
      <ScheduleSidebar
       v-show-feature="'schedule-sidebar'"
      />
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import {
  userHasRole,
  getCurrentUser,
  setCalendarView,
  setCalendarInterval,
  getCalendarInterval,
  userHasPermission
} from '@/utils/localStorageManager'
import moment from 'moment'
import ChevronLeft from '@/assets/icons/chevron-left.svg'
import ChevronRight from '@/assets/icons/chevron-right.svg'
import Search from '@/assets/icons/search.svg'
import Plus from '@/assets/icons/plus.svg'
import ScheduleLegend from '@/components/Schedule/ScheduleLegend'
import Autocomplete from '@/components/Autocomplete.vue'
import Calendar from '@/assets/icons/calendar.svg'
import Time from '@/assets/icons/time.svg'
import Select from '@/components/Select.vue'
import Filter from '@/assets/icons/filter.svg'
import Icon from '@/components/General/Icon'
export default {
  components: {
    'v-chevron-left': ChevronLeft,
    'v-chevron-right': ChevronRight,
    'v-search': Search,
    'v-autocomplete': Autocomplete,
    'v-plus': Plus,
    'v-schedule-legend': ScheduleLegend,
    'v-calendar': Calendar,
    'v-select': Select,
    'v-time': Time,
    'v-filter': Filter,
    Icon,
    MultiSelectProfessionals: () =>
      import('@/modules/schedule/components/MultiSelectProfessionals'),
    ScheduleSidebar: () =>
      import('@/modules/scheduleSidebar/views/Home'),
    Loading: () => import('@/assets/icons/loading.svg'),
    MultiSelectPriority: () =>
      import('@/modules/schedule/components/MultiSelectPriority')
  },
  directives: {
    visible(el, binding) {
      el.style.visibility = binding?.value ? 'visible' : 'hidden'
    }
  },
  props: {
    fullCalendar: Object,
    currentView: String,
    currentMonth: String,
    loadAppointments: Function,
    isSurgicalMapCurrentView:  {
      type: Boolean,
      default: false
    },
    // clinicRooms: Array,
    title: {
      type: String,
      default: 'Agenda'
    }
  },
  created() {
    this.getClinicRooms()
    this.getDoctors()
    this.setRange()
    this.createIntervalOptions()
  },
  data() {
    return {
      user: getCurrentUser(),
      clinic: JSON.parse(localStorage.getItem('clinic')),
      calendarTitle: moment().format('MMM [de] YYYY'),
      procedureType: null,
      roomId: null,
      professionalId: null,
      tempProcedureType: null,
      tempRoomId: null,
      clinicRooms: [],
      doctors: [],
      fatura: [
        { label: 'Faturado', value: 'paid_out' },
        { label: 'Precisa faturar', value: 'pending' }
      ],
      financeiroValue: null,
      tempFinanceiroValue: null,
      pendenciaVal: null,
      calendarRange: this.getTodayRange(),
      langDatePicker: {
        formatLocale: {
          weekdaysMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb']
        }
      },
      currentViewOption: this.currentView,
      calendarViewOptions: [
        { label: 'Mensal', value: 'dayGridMonth' },
        { label: 'Semanal', value: 'timeGridWeek' },
        { label: 'Diária', value: 'resourceTimeGridDay' }
      ],
      intervalOptions: [],
      showIntervalOptions: false,
      calendarInterval: getCalendarInterval(),
      procedureOptions: [
        { label: 'Centro cirúrgico', value: 'SURGICAL' },
        { label: 'Procedimento', value: 'PROCEDURE' },
        { label: 'Presencial', value: 'APPOINTMENT' },
        { label: 'Telemedicina', value: 'TELEMEDICINE' },
        { label: 'Exame', value: 'EXAM' },
        { label: 'Retorno', value: 'RETURN' }
      ],
      priorityLevelOptions: [
        { label: 'Não urgente', value: 'LOW' },
        { label: 'Pouco urgente', value: 'MEDIUM' },
        { label: 'Urgência', value: 'HIGH' },
        { label: 'Emergência', value: 'URGENT' }
      ],
      priorityLevelValue: [],
      interval: null,
      pendency: null,
      pendenciesOptions: [
        { label: 'Pendência TISS', value: 'tiss' },
        { label: 'Pendência Particular', value: 'particular' }
      ]
    }
  },
  computed: {
    ...mapState('schedule', ['loadingAppointments'])
  },
  methods: {
    userHasRole,
    userHasPermission,
    getPickerType() {
      const views = {
        dayGridMonth: 'month',
        timeGridWeek: 'week',
        timeGridDay: 'day'
      }
      return views[this.currentView] || 'day'
    },
    createIntervalOptions() {
      for (let i = 5; i <= 60; i += 5) {
        this.intervalOptions.push({ label: `${i} minutos`, value: i })
      }
    },
    setInterval(value) {
      setCalendarInterval(value)
      this.calendarInterval = getCalendarInterval()
      this.showIntervalOptions = false
      this.interval = value
      this.$emit('setInterval', value)
    },
    getTodayRange() {
      return {
        startDate: moment().startOf('month').startOf('week'),
        endDate: moment().endOf('month').endOf('week')
      }
    },
    setRange() {
      if (['resourceTimeGridDay', 'resourceTimeline'].includes(this.currentView)) {
        this.$set(this.calendarRange, 'startDate', moment().startOf('day'))
        this.$set(this.calendarRange, 'endDate', moment().endOf('day'))
        this.calendarTitle = this.calendarRange.startDate.format('dddd, DD [de] MMM')
      }
    },
    async prevOnCalendar() {
      const fullCalendarApi = this.fullCalendar.getApi()
      const actualStart = fullCalendarApi.view.currentStart
      let prevDate = moment()

      if (this.currentView === 'dayGridMonth') {
        prevDate = moment(actualStart).subtract(1, 'month')
      } else if (this.currentView === 'timeGridWeek') {
        prevDate = moment(actualStart).subtract(1, 'week')
        this.$emit('setOpeningHours')
      } else if (
        ['timeGridDay', 'resourceTimeline', 'resourceTimeGridDay'].includes(
          this.currentView
        )
      ) {
        prevDate = moment(actualStart).subtract(1, 'day')
        this.$emit('setOpeningHours', null, prevDate)
        this.setInterval(this.interval)
      }

      await fullCalendarApi.changeView(this.currentView, prevDate.toDate())
      this.calendarRange = {
        startDate: moment(fullCalendarApi.view.currentStart),
        endDate: moment(fullCalendarApi.view.currentEnd).subtract(1, 'day')
      }
      this.buildCalendarTitle(this.currentView)
      this.$emit('changeDate', this.calendarRange)
    },
    async nextOnCalendar() {
      const fullCalendarApi = this.fullCalendar.getApi()
      const actualStart = fullCalendarApi.view.currentStart
      let nextDate = moment()

      if (this.currentView === 'dayGridMonth') {
        nextDate = moment(actualStart).add(1, 'month')
      } else if (this.currentView === 'timeGridWeek') {
        nextDate = moment(actualStart).add(1, 'week')
        this.$emit('setOpeningHours', nextDate)
      } else if (
        ['timeGridDay', 'resourceTimeGridDay', 'resourceTimeline'].includes(this.currentView)
      ) {
        nextDate = moment(actualStart).add(1, 'day')
        this.$emit('setOpeningHours', null, nextDate)
        this.setInterval(this.interval)
      }

      await fullCalendarApi.changeView(this.currentView, nextDate.toDate())
      this.calendarRange = {
        startDate: moment(fullCalendarApi.view.currentStart),
        endDate: moment(fullCalendarApi.view.currentEnd).subtract(1, 'day')
      }

      this.buildCalendarTitle(this.currentView)
      this.$emit('changeDate', this.calendarRange)
    },
    async customOnCalendar(date) {
      const fullCalendarApi = this.fullCalendar.getApi()
      const nextDate = moment(date)
      if (this.currentView === 'timeGridWeek') {
        this.$emit('setOpeningHours', nextDate)
      }
      await fullCalendarApi.changeView(this.currentView, nextDate.toDate())
      this.calendarRange = {
        startDate: this.moment(fullCalendarApi.view.currentStart),
        endDate: this.moment(fullCalendarApi.view.currentEnd).subtract(1, 'day')
      }

      if (this.currentView === 'timeGridDay') {
        this.setInterval(this.interval)
      }

      this.buildCalendarTitle(this.currentView)
      this.$refs.datePicker.closePopup()
      this.$emit('changeDate', this.calendarRange)
    },
    buildCalendarTitle(currentView) {
      const fullCalendarApiView = this.fullCalendar.getApi().view
      const startDate = moment(fullCalendarApiView.currentStart)
      const endDate = moment(fullCalendarApiView.currentEnd).subtract(1, 'days')

      if (currentView === 'dayGridMonth') {
        const calendarTitle = startDate.format('MMM [de] YYYY')
        calendarTitle[0].toUpperCase()
        this.calendarTitle = calendarTitle
      } else if (currentView === 'timeGridWeek') {
        this.calendarTitle = `${startDate.format(
          'DD [de] MMM'
        )} - \n${endDate.format('DD [de] MMM')}`
      } else if (
        ['timeGridDay', 'resourceTimeGridDay'].includes(this.currentView) ||
        this.isSurgicalMapCurrentView
      ) {
        this.calendarTitle = startDate.format('dddd, DD [de] MMM')
        this.setInterval(this.interval)
      }
    },
    getClinicRooms() {
      this.api
        .getClinicRooms(this.clinic.id)
        .then(res => {
          this.clinicRooms = res.data.data
        })
        .catch(err => console.log(err))
    },
    openModal() {
      if (this.isSurgicalMapCurrentView) {
        this.$emit('open-room-reservation-modal')
        return
      }
      this.$bvModal.show('appointment-modal')
      this.$emit('openAppointment', null)
    },
    getDoctors() {
      this.api
        .getClinicDoctors(this.clinic.id)
        .then(({ data }) => {
          this.doctors = data.doctors.map(doctor => ({
            value: doctor.id,
            label: doctor.name,
            el: doctor
          }))

          if (userHasRole('DOCTOR')) {
            const doctor = this.doctors.find(
              doctor => doctor.value === this.user.id
            )
            if (doctor) {
              this.professionalId = doctor.value
              this.doctors = [doctor]
            }
          }
        })
        .catch(console.log)
    },
    async todayButton() {
      const fullCalendarApi = this.fullCalendar.getApi()
      await fullCalendarApi.changeView(this.currentView, this.moment().toDate())

      const startDate = this.moment()
      const endDate = this.moment()

      await fullCalendarApi.changeView(this.currentView, startDate.toDate())

      if (this.currentView === 'dayGridMonth') {
        startDate.startOf('month').startOf('week')
        endDate.endOf('month').endOf('week')
      } else if (this.currentView === 'timeGridWeek') {
        startDate.startOf('week')
        endDate.endOf('week')
      } else if (
        ['timeGridDay', 'resourceTimeGridDay', 'resourceTimeline'].includes(this.currentView)
      ) {
        startDate.startOf('day')
        endDate.endOf('day')
        this.$emit('setOpeningHours', null, moment().format('YYYY-MM-DD'))
        this.setInterval(this.interval)
      }

      this.calendarRange = { startDate, endDate }
      this.buildCalendarTitle(this.currentView)
      this.$emit('changeDate', this.calendarRange)
    },
    async changeView(view) {
      const fullCalendarApi = await this.fullCalendar.getApi()
      await fullCalendarApi.changeView(view)
      await fullCalendarApi.updateSize()
      this.buildCalendarTitle(view)
      this.todayButton()
      this.$emit('changeCurrentView', view)
    },
    showTodayButton() {
      return (
        (this.currentView === 'dayGridMonth' &&
          this.fullCalendar &&
          this.currentMonth !==
            moment(this.fullCalendar.getApi().view.currentStart).format(
              'MM'
            )) ||
        (this.currentView === 'timeGridWeek' &&
          !moment().isBetween(
            this.calendarRange.startDate,
            this.calendarRange.endDate
          )) ||
        (['timeGridDay', 'resourceTimeGridDay', 'resourceTimeline'].includes(this.currentView) &&
          !moment().isSame(this.calendarRange.endDate, 'day'))
      )
    },
    cleanFilter() {
      this.tempFinanceiroValue = null
      this.tempRoomId = null
      this.tempProcedureType = null
      this.pendency = null
      this.priorityLevelValue = []
      this.confirmFilter()
    },
    confirmFilter() {
      this.financeiroValue = this.tempFinanceiroValue
      this.roomId = this.tempRoomId
      this.procedureType = this.tempProcedureType
      const filters = {
        professionalId: this.professionalId,
        financeiroValue: this.financeiroValue,
        roomId: this.roomId,
        procedureType: this.procedureType,
        pendency: this.pendency,
        priorityLevel: this.priorityLevelValue
      }
      this.$emit('filterChanged', filters)
    },
    openFilter() {
      this.tempRoomId = this.roomId
      this.tempProcedureType = this.procedureType
      this.tempFinanceiroValue = this.financeiroValue
    }
  },
  watch: {
    professionalId() {
      const professional = this.doctors.find(
        d => d.value === this.professionalId
      )
      this.$emit('changeProfessional', professional ? professional.el : null)
    },
    async currentViewOption(option) {
      await this.changeView(option)
      if (!this.isSurgicalMapCurrentView) {
        setCalendarView(this.user.cpf, option)
      }
    },
    currentView: {
      handler(newValue) {
        if (['timeGridDay', 'resourceTimeGridDay'].includes(newValue)) {
          const startDate = this.moment()
          this.calendarTitle = startDate.format('dddd, DD [de] MMM')
        }
        if (this.isSurgicalMapCurrentView) {
          this.calendarViewOptions = [
            { label: 'Quadro', value: 'resourceTimeline' },
            { label: 'Lista', value: 'resourceTimeGridDay' }
          ]
          this.calendarTitle =
            this.calendarRange.startDate.format('DD [de] MMM')
          return
        }
        this.calendarViewOptions = [
          { label: 'Mensal', value: 'dayGridMonth' },
          { label: 'Semanal', value: 'timeGridWeek' },
          { label: 'Diária', value: 'resourceTimeGridDay' }
        ]
      },
      immediate: true
    }
  }
}
</script>
<style lang="scss">
#calendar-header-wrapper {
  width: 100%;
  padding: 0 20px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  &.week {
    height: 10% !important;
  }
  ::-moz-selection {
    background: transparent;
  }
  ::selection {
    background: transparent;
  }
  .calendar-header-title {
    display: inline-flex;
    align-items: center;
    gap: 5px;

    .title {
      margin: 28px 5px 28px 0;
      font-family: 'Red Hat Display';
      font-weight: 700;
      font-size: 1.5rem;
      color: var(--dark-blue);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .calendar-datepicker {
    display: flex;
    align-items: center;
    margin-right: auto;
    margin-left: 2%;

    .mx-datepicker {
      width: 250px;
      display: flex;
      justify-content: space-between;

      .mx-input-wrapper {
        width: 240px;
        .mx-input {
          padding: 0;
        }
      }
    }
  }

  .calendar-icon {
    width: 24px;
    height: 24px;
    fill: var(--blue-500);
  }

  .chevron {
    width: 24px;
    height: 24px;
    stroke: var(--blue-500);
    cursor: pointer;
  }
  .date {
    font-weight: 600;
    font-size: 18px;
    margin: 0px;
    color: var(--type-active);
    text-align: center;
    margin: 0 8px;
    &.month {
      /* width: 120px; */
    }
    .mx-input {
      cursor: pointer;
      border: 0px !important;
      caret-color: transparent;
    }
    .mx-input::focus {
      outline: none !important;
      border-color: inherit;
    }
    .mx-input::placeholder {
      text-align: center;
      font-size: 18px !important;
      font-weight: 600 !important;
      opacity: 1;
      color: var(--type-active);
    }
  }
  .multiselect {
    .multiselect__tags {
      width: 125px;
      height: 100%;
      border: 2px solid var(--blue-500);

      .multiselect__single {
        font-weight: 600;
        color: var(--blue-500);
      }
    }
    &.multiselect--active {
      width: auto;
      .multiselect__tags {
        border: 2px solid var(--blue-500);
      }
    }
  }
  .calendar-header-actions {
    display: inline-flex;
    align-items: center;
    gap: 5px;

    .interval-options {
      z-index: 3;
      position: absolute;
      margin-top: 4px;
      margin-left: -150px;
      width: 200px;
      height: 250px;
      overflow-x: auto;
      border-radius: 8px;
      border: 1px solid var(--neutral-200);
      background-color: var(--neutral-000);
      box-shadow: 0px 5px 5px var(--neutral-200);

      p {
        transition: 0.3s;
        padding: 10px 15px;

        &:hover {
          transition: 0.3s;
          background: var(--neutral-100);
          cursor: pointer;
        }
      }

      .active {
        background: var(--blue-100);
      }
    }

    .search-button {
      width: 56px;
      height: 38px;
      padding: 8px 16px 8px 16px;
      border: 1px solid #c6ceeb;
      box-sizing: border-box;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      svg {
        height: 20px;
        width: 20px;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 0px 10px;
        stroke: #a4b1df;
      }
    }
  }
  .btn-new-appointment {
    display: flex;
    align-items: center;
    height: 38px;
    .icon-plus {
      width: 20px;
      height: 20px;
      fill: var(--neutral-000);
    }
  }
  .calendar-options {
    width: 125px;
    height: 38px;

    .select-container {
      .select-value {
        color: var(--blue-500);
        font-weight: 600;
      }
    }
  }
  .filter {
    background-color: #ffffff;
    border: 1px solid #c6ceeb;
    border-radius: 8px;
  }
}
</style>

<style lang="scss" scoped>
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  background: var(--neutral-000);
  border-radius: 0 0 8px 0;
}

::-webkit-scrollbar-thumb {
  background-color: var(--neutral-300);
  border-radius: 100px;
  border: 3px solid var(--neutral-000);
}

::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
  background: var(--neutral-200);
}
</style>
