<template>
  <div class="schedule-legend-container">
    <Help
      class="help-icon"
      @mouseover="tooltipContainerStatus = true"
      @mouseleave="tooltipContainerStatus = false"
    />
    <div class="tooltip-container" :class="{ active: tooltipContainerStatus }">
      <div class="situations-container">
        <p class="situations-container-title">{{ title }}</p>

        <div class="status-situations">
          <div
            v-for="(status, index) in statusOptions"
            :key="index"
            class="situation"
            :class="status.value"
          >
            <component :is="status?.icon" />
            <p>{{ status.label }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Allocate from '@/assets/icons/allocate.svg'
import Appointment from '@/assets/icons/appointment.svg'
import Calendar from '@/assets/icons/calendar.svg'
import Exam from '@/assets/icons/exam.svg'
import Eye from '@/assets/icons/eye.svg'
import EyeExams from '@/assets/icons/eye-exams.svg'
import Financial from '@/assets/icons/financial.svg'
import Help from '@/assets/icons/lightblue-help-circle.svg'
import Procedure from '@/assets/icons/procedure.svg'
import Reschedule from '@/assets/icons/reschedule.svg'
import SurgicalProcedure from '@/assets/icons/surgical-procedure.svg'
import Telemedicine from '@/assets/icons/telemedicine.svg'

export default {

  components: {
    Help,
    Reschedule,
    Allocate,
    Calendar,
    EyeExams,
    Eye,
    Telemedicine,
    Procedure,
    Financial,
    Appointment,
    Exam,
    SurgicalProcedure
  },
  props: {
    isSurgicalMap: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    tooltipContainerStatus: null
  }),
  computed: {
    title() {
      return this.isSurgicalMap
        ? 'Cores das situações'
        : 'Cores e ícones de agendamentos'
    },
    statusOptions() {
      if (this.isSurgicalMap) {
        return [
          {
            label: 'Reservado',
            value: 'map reserved_map'
          },
          {
            label: 'Sala de recuperação',
            value: 'map recovery_room_map'
          },
          {
            label: 'Confirmado',
            value: 'map confirmed_map'
          },
          {
            label: 'Sala bloqueada',
            value: 'map block_room'
          },
          {
            label: 'Primeiro atendimento',
            value: 'map first_attendance_map'
          },
          {
            label: 'Sala reservada',
            value: 'map reserved_room'
          },
          {
            label: 'Admissão finalizada',
            value: 'map admission_completed_map'
          },
          {
            label: 'Cancelado',
            value: 'map canceled_map'
          },
          {
            label: 'Pronto para cirurgia',
            value: 'map ready_for_surgery_map'
          },
          {
            label: 'Faltou',
            value: 'map missed_map'
          },
          {
            label: 'Em cirurgia',
            value: 'map in_surgery_map'
          },
          {
            label: 'Finalizado',
            value: 'finished'
          }
        ]
      }
      return [
        {
          label: 'Sem situação',
          value: 'scheduled'
        },
        {
          label: 'Confirmado',
          value: 'confirmed'
        },
        {
          label: 'Compareceu',
          value: 'attend'
        },
        {
          label: 'Aguard. atendimento',
          value: 'waiting'
        },
        {
          label: 'Em pré-consulta',
          value: 'in_pre_finished'
        },
        {
          label: 'Pré-consulta finalizada',
          value: 'icon fit pre_finished',
          icon: Calendar
        },
        {
          label: 'Em atendimento médico',
          value: 'happening'
        },
        {
          label: 'Paciente em exames',
          value: 'icon fit examinating',
          icon: EyeExams
        },
        {
          label: 'Paciente dilatando',
          value: 'icon fit dilating',
          icon: Eye
        },
        {
          label: 'Atendimento finalizado',
          value: 'finished'
        },
        {
          label: 'Paciente faltou',
          value: 'missed'
        },
        {
          label: 'Cancelado',
          value: 'canceled'
        },
        {
          label: 'Reagendamento',
          value: 'icon fit rescheduling pad-lef-5',
          icon: Reschedule
        },
        {
          label: 'Encaixe',
          value: 'icon fit allocate pad-lef-5',
          icon: Allocate
        },
        {
          label: 'Telemedicina',
          value: 'icon fit telemedicine pad-lef-5',
          icon: Telemedicine
        },
        {
          label: 'Procedimento',
          value: 'icon fit procedure pad-lef-5',
          icon: Procedure
        },
        {
          label: 'Faturado',
          value: 'icon fit financial pad-lef-5',
          icon: Financial
        },
        {
          label: 'Consulta',
          value: 'icon fit appointment pad-lef-5',
          icon: Appointment
        },
        {
          label: 'Exame',
          value: 'icon fit exam pad-lef-5',
          icon: Exam
        },
        {
          label: 'Centro cirúrgico',
          value: 'icon fit surgical-procedure pad-lef-5',
          icon: SurgicalProcedure
        }
      ]
    }
  }
}
</script>
<style lang="scss" scoped>
.schedule-legend-container {
  position: relative;
}
.help-icon {
  height: 24px !important;
  width: 24px !important;
}
.icon-exam {
  height: 15px;
  width: 15px;
}
.tooltip-container {
  position: absolute;
  height: auto;
  width: 398px;
  display: none;
  top: 30px;
  left: 0;
  z-index: 2;
  background-color: #ffffff;
  border-radius: 8px;
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  .situations-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin: 24px;
    p {
      font-size: 16px;
      line-height: 150%;
      color: #0c1d59;
      font-weight: 700;
      &.situations-container-title {
        margin: 0;
      }
    }
    .status-situations {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 8px;
      .situation {
        display: flex;
        flex-direction: row;
        align-items: center;
        text-align: left;
        height: auto;
        width: auto;
        background: blue;
        padding: 6px 10px;
        border-radius: 4px;
        p {
          font-size: 12px;
          line-height: 130%;
          margin: 0;
        }
        &.icon {
          display: flex;
          gap: 8px;
        }
        &.fit {
          svg {
            height: 16px !important;
            width: auto !important;
          }
          &.pad-lef-5 {
            padding-left: 5px;
          }
        }
        &.rescheduling {
          border: thick;
          background-color: #ffffff !important;
          p {
            color: #525c7a;
          }
        }
        &.allocate {
          border: thick;
          background-color: #ffffff;

          p {
            color: #525c7a;
          }
        }
        &.telemedicine {
          border: thick;
          background-color: #ffffff;

          p {
            color: #525c7a;
          }
        }
        &.procedure {
          border: thick;
          background-color: #ffffff;

          p {
            color: #525c7a;
          }
        }
        &.financial {
          border: thick;
          background-color: #ffffff;

          p {
            color: #525c7a;
          }
        }
        &.appointment {
          border: thick;
          background-color: #ffffff;

          p {
            color: #525c7a;
          }
        }
        &.exam {
          width: auto;
        }
        &.allocate {
          border: thick;
          background-color: var(--neutral-000);

          p {
            color: var(--type-active);
          }
        }
        &.telemedicine {
          border: thick;
          background-color: var(--neutral-000);

          p {
            color: var(--type-active);
          }
        }
        &.procedure {
          border: thick;
          background-color: var(--neutral-000);

          p {
            color: var(--type-active);
          }
        }
        &.financial {
          border: thick;
          background-color: var(--neutral-000);

          p {
            color: var(--type-active);
          }
        }
        &.appointment {
          border: thick;
          background-color: var(--neutral-000);

          p {
            color: var(--type-active);
          }
        }
        &.exam {
          border: thick;
          background-color: var(--neutral-000);

          p {
            color: var(--type-active);
          }
        }
        &.scheduled {
          background-color: var(--neutral-000) !important;
          border: 1px solid var(--neutral-200);

          p {
            color: var(--type-active);
          }
        }
        &.confirmed {
          background-color: #f2ffe6;

          p {
            color: #046340;
          }
        }
        &.attend {
          background-color: #e6f5ff;

          p {
            color: var(--blue-700);
          }
        }
        &.waiting {
          background-color: #fff6d5;

          p {
            color: #80430b;
          }
        }
        &.pre_finished {
          background-color: #ffefe4;
          justify-content: space-between;

          p {
            color: #4a280e;
          }
          svg {
            fill: #6c3350;
            transform: scale(1.125);
            order: 2;
          }
        }
        &.in_pre_finished {
          background-color: #dcfaff;

          p {
            color: #23acb1;
          }
        }
        &.happening {
          background-color: #fff3e1;

          p {
            color: var(--light-orange-700);
          }
        }
        &.examinating {
          background-color: #ffe3f1;
          justify-content: space-between;

          p {
            color: #6c3350;
          }
          svg {
            fill: #6c3350;
            transform: scale(1.125);
            order: 2;
          }
        }
        &.dilating {
          background-color: #fbf0ff;
          justify-content: space-between;
          
          p {
            color: #6c3350;
          }
          svg {
            fill: #673270;
            transform: scale(1.125);
            order: 2;
          }
        }
        &.finished {
          background-color: #f8fdff;
          border: 1px solid var(--neutral-600);

          p {
            color: var(--neutral-600);
          }
        }
        &.missed {
          background-color: #fff7f7;

          p {
            color: #d82828;
          }
        }
        &.canceled {
          background-color: #5266af;

          p {
            color: var(--neutral-000);
          }
        }
        &.surgical-procedure {
          border: thick;
          background-color: var(--neutral-000);

          p {
            color: var(--type-active);
          }
        }
        &.map {
          border: none !important;
          p {
            color: var(--neutral-000);
          }

          &.reserved_map {
            background-color: #486ef6;
          }

          &.confirmed_map {
            background-color: #00977c;
          }

          &.first_attendance_map {
            background-color: #00c2ff;
          }

          &.admission_completed_map {
            background-color: #04e36b;
          }

          &.ready_for_surgery_map {
            background-color: #c556d7;
          }

          &.in_surgery_map {
            background-color: #ffab28;
          }

          &.recovery_room_map {
            background-color: #c27245;
          }

          &.block_room {
            background-color: #c6ceeb;

            p {
              color: var(--neutral-700);
            }
          }

          &.reserved_room {
            background-color: #bdffeb;

            p {
              color: var(--neutral-700);
            }
          }

          &.canceled_map {
            background-color: #7f91d2;
          }

          &.missed_map {
            background-color: #ff6969;
          }
        }
      }
    }
  }
  &.active {
    display: unset !important;
  }
}
</style>
